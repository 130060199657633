import './Home.css'
import {useNavigate} from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate()

    return (
        <div className='home-page'>
            <div className='container'>
                <div className='row'>
                    <img src='/images/login.svg' alt='logo' />
                    <div className='home-page-title'>Cartas demo</div>
                    <button onClick={() => navigate('/bar')}>
                        <div className='home-page-button-img'>
                            <img src='/favicon.ico' alt='qr-code' />
                        </div>
                        Bar
                    </button>
                    <button onClick={() => navigate('/cafeteria')}>
                        <div className='home-page-button-img'>
                            <img src='/favicon.ico' alt='qr-code' />
                        </div>
                        Cafetería
                    </button>
                    <button onClick={() => navigate('/comida-vegana')}>
                        <div className='home-page-button-img'>
                            <img src='/favicon.ico' alt='qr-code' />
                        </div>
                        Comida vegana
                    </button>
                    <button onClick={() => navigate('/hamburgueseria')}>
                        <div className='home-page-button-img'>
                            <img src='/favicon.ico' alt='qr-code' />
                        </div>
                        Hamburguesería
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Home