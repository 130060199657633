import './Item.css';
import { useState, useEffect, useRef } from 'react';
import { useClients } from '../../context/ClientContext';

const Item = (props) => {
    const [mostrarTodo, setMostrarTodo] = useState(false)
    const [descriptionLines, setDescriptionLines] = useState(0)
    const [imageLoaded, setImageLoaded] = useState(false)
    const descriptionRef = useRef(null)
    const descriptionRefInner = useRef(null)
    const {client} = useClients()

    useEffect(() => {
        if (descriptionRef.current && descriptionRefInner.current) {
            const height = descriptionRefInner.current.clientHeight
            const scrollHeight = descriptionRefInner.current.scrollHeight
            const elementHeight = scrollHeight > height ? scrollHeight : height
            const maxHeight = 32;
    
            if (elementHeight > maxHeight) {
                setDescriptionLines(Math.ceil(elementHeight / 16));
            }
        }
    }, []);  

    const sobrePasaDescripcion = () => descriptionRefInner?.current?.scrollHeight > 32

    const esPrecio = () => {
        return typeof props.price !== "string" || props.price.toLowerCase() !== "sin stock"
    }

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })

    const redirigir = () => {
        if(!props.link == "") {
            window.location.href = props.link;
        }
    }

    return <ul className='col-12 col-md-6 item-box'>
            <div className={client.color_cuerpo_claro ? 'item' : 'item oscuro'} key={props.index}>
                <div onClick={redirigir} className={props.has_image ? 'itemText' : 'itemTextLarge'}>
                    <div>
                        <li className={client.color_cuerpo_claro ? 'name' : 'name oscuro'}>{props.name}</li>
                        <div
                            ref={descriptionRef}
                            className={`description-box ${mostrarTodo ? 'full' : ''}`}
                            style={{height: (mostrarTodo || !sobrePasaDescripcion()) ? descriptionRefInner?.current?.scrollHeight : '32px'}}
                            onClick={() => sobrePasaDescripcion() ? setMostrarTodo(!mostrarTodo) : ''}
                        >
                            <li className={client.color_cuerpo_claro ? 'description' : 'description oscuro'} ref={descriptionRefInner}>
                                {props.description}
                            </li>
                        </div>
                        {(mostrarTodo || sobrePasaDescripcion()) && (
                            <div 
                                className={client.color_cuerpo_claro ? 'leer' : 'leer oscuro'} 
                                onClick={() => setMostrarTodo(!mostrarTodo)}>
                                {mostrarTodo ? 'Leer menos' : 'Leer más'}
                            </div>
                        )}
                    </div>
                    <div className='precio-box'>
                        <li className={!props.has_stock ? 'precio precio-sinstock' : client.color_cuerpo_claro ? 'precio' : 'precio oscuro'}>
                            {props.has_stock ? formatterPeso.format(props.price) : 'Sin stock'}
                        </li>
                    </div>
                </div>
                {props.has_image && <div className='itemImg'>
                    <div className={imageLoaded ? 'itemImg-box' : (client.color_cuerpo_claro ? 'itemImgLoader-box' : 'itemImgLoader-box oscuro')}>
                        <img src={props.image_url} alt={props.name} onLoad={() => setImageLoaded(true)} />
                    </div>
                </div>
                }
            </div>
        </ul>
}

export default Item;