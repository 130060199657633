import { createContext, useContext, useState } from "react";
import { getProductsRequest, getPublicProfileRequest, getCategoriesRequest } from '../api/client.js'

const ClientContext = createContext()

export const useClients = () => {
    const context = useContext(ClientContext)
    if(!context) throw new Error("useClients must be used within an TaskProvider")

    return context
}

export const ClientProvider = ({children}) => {
    const [client, setClient] = useState(null)
    const [clientNotFound, setClientNotFound] = useState(false)

    const getProducts = async (username) => {
        try {
            const res = await getProductsRequest(username)
            return res.data
        } catch(error) {
            console.log(error)
        }
    }

    const getPublicProfile = async (username) => {
        try {
            const res = await getPublicProfileRequest(username)
            const client = res.data
            setClient(client)
            return client
        } catch(error) {
            if(error.response.status === 400 && error.response.data.message === "Cliente no encontrado") {
                setClientNotFound(true)
            }
            console.log(error)
        }
    }

    const getCategories = async (username) => {
        try {
            const res = await getCategoriesRequest(username)
            return res.data
        } catch(error) {
            console.log(error)
        }
    }

    return(
        <ClientContext.Provider value={{client, getProducts, getPublicProfile, clientNotFound, getCategories}}>
            {children}
        </ClientContext.Provider>
    )
}