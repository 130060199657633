import './Navbar.css';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext.js';
import { useClients } from '../../context/ClientContext.js';

const Navbar = ({isLoading}) => {
    const {isAuthenticated, logOut, user} = useAuth();
    const {client, clientNotFound} = useClients()
    const [isActiveUser, setIsActiveUser] = useState(false)

    const cerrarSesion = () => {
        logOut()
    }

    const redirigirDashboard = () => {
        window.location.href = 'https://admin.pido-online.com.ar'
    }

    const clickIconoUser = () => {
        setIsActiveUser(!isActiveUser)
    }

    document.addEventListener('click', function(event) {
        if(event.target.id != 'navbar-icono' && event.target.id != 'navbar-icono-user' && event.target.id != 'navbar-expand' && event.target.id != 'navbar-expand-cerrar-sesion' && event.target.id != 'navbar-expand-dashboard' && event.target.id != 'navbar-expand-username' && event.target.id != 'navbar-expand-username-span'){
            setIsActiveUser(false)
        }
    });

    return (
        <div className={isLoading || clientNotFound || client.color_navbar_claro ? 'navbar' : 'navbar oscuro'}>
            {isLoading || clientNotFound || !client.has_logo
            ? <img src="/images/navbar-pido-online.svg" alt='logo' />
            : <img src={client.logo_url} alt='logo' />
            }
            {
            isAuthenticated && 
            (isLoading || clientNotFound || client.color_navbar_claro
            ? <div className='navbar-controls'>
                <div className='navbar-controls-username'>¡Hola <span>{user.username}</span>!</div>
                <div id='navbar-icono' onClick={clickIconoUser} className={isActiveUser ? 'navbar-icono navbar-active' : 'navbar-icono'}>
                    <img id='navbar-icono-user' className={isActiveUser ? 'navbar-icono-user navbar-active' : 'navbar-icono-user'} src='/images/icono-user.png' alt='icono user' />
                </div>
                <div id='navbar-expand' className={isActiveUser ? 'navbar-expand navbar-active' : 'navbar-expand'}>
                    <div id='navbar-expand-username'>¡Hola <span id='navbar-expand-username-span'>{user.username}</span>!</div>
                    <div id='navbar-expand-dashboard' onClick={redirigirDashboard}>Dashboard</div>
                    <div id='navbar-expand-cerrar-sesion' onClick={cerrarSesion}>Cerrar sesión</div>
                </div>
            </div>
            : <div className='navbar-controls oscuro'>
                <div className='navbar-controls-username'>¡Hola <span>{user.username}</span>!</div>
                <div id='navbar-icono' onClick={clickIconoUser} className={isActiveUser ? 'navbar-icono oscuro navbar-active' : 'navbar-icono oscuro'}>
                    <img id='navbar-icono-user' className={isActiveUser ? 'navbar-icono-user oscuro navbar-active' : 'navbar-icono-user oscuro'} src='/images/icono-user.png' alt='icono user' />
                </div>
                <div id='navbar-expand' className={isActiveUser ? 'navbar-expand navbar-active' : 'navbar-expand'}>
                    <div id='navbar-expand-username'>¡Hola <span>{user.username}</span>!</div>
                    <div id='navbar-expand-dashboard' onClick={redirigirDashboard}>Dashboard</div>
                    <div id='navbar-expand-cerrar-sesion' onClick={cerrarSesion}>Cerrar sesión</div>
                </div>
            </div>
            )
            }
        </div>
    );
}

export default Navbar;