import './App.css';
import {useEffect, useState} from 'react';
import SEO from './Components/SEO/SEO';
import Categorias from './Components/Categorias/Categorias';
import Navbar from './Components/Navbar/Navbar';
import List from './Components/List/List';
import Footer from './Components/Footer/Footer';
import { Spinner } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useClients } from './context/ClientContext.js';
import { useParams } from 'react-router-dom';
import { MdErrorOutline, MdOutlineRemoveShoppingCart } from "react-icons/md";

const App = () => {
  const initialDates = [{
    ID: "",
    Name: "",
    Description: "",
    Price: "",
    Category: "",
    Image: ""
  }]

  const {username} = useParams()
  const {getProducts, getPublicProfile, client, clientNotFound, getCategories} = useClients()
  const [datos, setDatos] = useState(initialDates);
  const [categorias, setCategorias] = useState([]);
  const [catActiva, setCatActiva] = useState("Todos"); 
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {  
      const obtenerCategorias = (result, categoria_todos_activado) => {
        let catArr = []
        if(categoria_todos_activado) {
          catArr = ["Todos"]
        }
        const cat = result.map(row => {
          if (!catArr.includes(row.name)) {
            catArr.push(row.name);
          }
        })
        
        if (catArr.includes("")) {
          catArr = catArr.filter(c => c != "")
        }
        setCategorias(catArr);
        if(!categoria_todos_activado) {
          setCatActiva(catArr[0]);
        }
      }

      const getClient = async () => {
        setIsLoading(true);
        const profile = await getPublicProfile(username);
        const result = await getProducts(username);
        const categories = await getCategories(username);
        setDatos(result);
        obtenerCategorias(categories, profile?.categoria_todos);
        setIsLoading(false);
      }

      getClient();
  }, [])

  const seleccionada = (activa) => {
    setCatActiva(activa);
  }

  return (
    <div className={isLoading || clientNotFound || client.color_cuerpo_claro ? 'app' : 'app oscuro'}>
      <Navbar isLoading={isLoading} />
      <div className='container contenedor'>
        <link rel="stylesheet" href="/fonts/fonts.css" />
        {!isLoading
        ? clientNotFound || !client.active
          ? <div className='cuerpo no-disponible'> 
              <SEO client={client} clientNotFound={clientNotFound} />
              { clientNotFound
              ? <div>
                  <MdErrorOutline size='50px' />
                  Carta no encontrada<br/>
                  Verifique la URL
                </div>
              : <div>
                  <MdOutlineRemoveShoppingCart size='50px' />
                  Carta no disponible
                </div>
              }
            </div>
          : <div className='cuerpo'>
            <SEO client={client} clientNotFound={clientNotFound} />
            <Categorias datos={categorias} selec={seleccionada} isLoading={isLoading} />
            <List datos={datos} selec={catActiva} isLoading={isLoading} />
            {client.whatsapp && (
              <>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
                <a href={`https://wa.me/549${client.whatsapp}`} className="float" target="_blank">
                  <i className="fa fa-whatsapp my-float"></i>
                </a>
              </>
              )
            }
          </div>
        : <div className={isLoading || clientNotFound || client.color_cuerpo_claro ? 'spinnerContainer' : 'spinnerContainer oscuro'}>
            <Spinner />
          </div>
        }
      </div>
      {(isLoading || clientNotFound || client.footer_active) && <Footer isLoading={isLoading} />}
    </div>
  );
}

export default App;
