import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"

const SEO = ({client, clientNotFound}) => {
    const {username} = useParams()

    return (
        <>
        {clientNotFound
        ? <Helmet>
            <title>Pido-online | Digital menu</title>
            <meta name="description" content='Carta no encontrada, verifique la URL' />
            <meta name="keywords" content='pido-online pidoonline pido online carta qr menu digital precios' />
            <link rel="icon" href="/favicon.ico" />
            <meta property="og:title" content='Pido-online | Digital menu' />
            <meta property="og:description" content='Carta no encontrada, verifique la URL' />
            <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
            <meta property="og:url" content={`https://pido-online.com/${username}`} />
        </Helmet>
        : <Helmet>
            <title>{`${client.title || client.username} | Digital menu`}</title>
            <meta name="description" content={`Perfil de ${client.title || client.username}`} />
            <meta name="keywords" content={`pido-online pidoonline pido online carta qr menu digital precios ${client.username}${client.title ? ` ${client.title}` : ''}`} />
            <link rel="icon" href="/favicon.ico" /> 
            <meta property="og:title" content={`${client.title || client.username} | Digital menu"`} />
            <meta property="og:description" content={`Visita el perfil de ${client.title || client.username}`} />
            <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
            <meta property="og:url" content={`https://pido-online.com/${client.username}`} />
        </Helmet>
        }
        </>
    )
}

export default SEO