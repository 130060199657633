import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext.js';
import { ClientProvider } from './context/ClientContext.js';
import App from './App.js'
import NotFoundPage from './NotFoundPage.js';
import Home from './Home.js';

const QrMenu = () => {
  const RedirectToUsername = () => {
    const { username } = useParams()
    return <Navigate to={`/${username}`} replace />
  }

  return (
    <AuthProvider>
        <ClientProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/:username" element={<App />} />
                    <Route path="/:username/*" element={<RedirectToUsername />} />
                    <Route path="*" element={<Home />} />
                    {/* <Route path="*" element={<NotFoundPage />} /> */}
                </Routes>
            </BrowserRouter>
        </ClientProvider>
    </AuthProvider>
  );
}

export default QrMenu;